
import { combineReducers, createStore, applyMiddleware } from 'redux';
import loginReducer from './redux/reducer/login/loginReducers';
import accessCategoryReducer from './redux/reducer/accessCategory/accessCategoryReducers';
import userDataReducer from './redux/reducer/userData/userDataReducers';
import selectedLanguageReducer from './redux/reducer/selected-language/selected-language-reducer';
import listOfPrincipalReducer from './redux/reducer/listOfPrincipals/listOfPrincipalReducers';
import ticketReducers from './redux/reducer/tickets/ticketReducers';
import configDataReducer from './redux/reducer/configData/configDataReducers';
import teamMembersReducer from './redux/reducer/getTeamMembers/teamMembersReducers';
import getCreatoresReducers from './redux/reducer/getTeamMembers/getCreatoresReducers';
import dashBoardTypeReducer from './redux/reducer/dashBoardByUserId/dashBoardByUserIdReducer';
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    loginReducer,
    accessCategoryReducer,
    userDataReducer,
    selectedLanguageReducer,
    listOfPrincipalReducer,
    ticketReducers,
    configDataReducer,
    teamMembersReducer,
    getCreatoresReducers,
    dashBoardTypeReducer
});

export default createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunk)
));