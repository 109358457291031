import { SELECTED_LANGUAGE_CHANGED } from '../../actionTypes/selected-language/selected-language-acion-types';
  
  const initialState = {
    selectedLang: 'en'//TO DO - get from config
  };
  
  const selectedLanguageReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case SELECTED_LANGUAGE_CHANGED:
        const selectedLang = action.payload.selectedLang;
        return {
          ...state,
          selectedLang: selectedLang
        };
      default:
        return state;
    }
  };
  
  export default selectedLanguageReducer;