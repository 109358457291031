import {
  UPDATE_ACCESS_CAT_DATA
} from '../../actionTypes/accessCategory/accessCategoryActionTypes';
import { AccessCategoryData } from '../../actions/accessCategory/accessCategoryActionData';

const initialState = {
  accessCatData: []
};

const accessCategoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_ACCESS_CAT_DATA:
      const catData: AccessCategoryData = action.payload.data;
      catData && catData.length > 0 && catData.map((ele: any) => {
        ele['accessValue'] = 'na';
      });
      return {
        ...state,
        accessCatData: catData
      };
    default:
      return state;
  }
};

export default accessCategoryReducer;
