import React from 'react';
import ReactDOM from 'react-dom';
import { Suspense } from 'react';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals'; 
import {Provider}  from 'react-redux';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { stopReportingRuntimeErrors } from "react-error-overlay";

import './i18n';

// if (process.env.NODE_ENV === "development") {
//   stopReportingRuntimeErrors(); // disables error overlays
// }
if (window.self === window.top) {
ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode> 
  <Suspense fallback="loading...">
    <App />
  </Suspense>  
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
