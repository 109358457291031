import React, { lazy, Suspense } from 'react';
import { Switch, Route } from "react-router-dom";
import PrivateRoute from './routing/private-route';
import AdminRoute from './routing/admin-login';
import Spinner from "./components/Spinner/spinner";

// Import components
const LoginPage = lazy(() => import('./components/login/login'));
const CreatePassword = lazy(() => import('./components/passwordSetUp/CreatePassword'));
const OtpLogin = lazy(() => import('./components/otpLogin/otpLogin'));
const ResetPassword = lazy(() => import('./components/resetPassword/resetPassword'));
const ForgotPassword = lazy(() => import('./components/forgotPassword/forgotPassword'));
const PrincipalTermAndConditions = lazy(() => import('./components/termsAndConditions/principalTermAndConditions'));
const EnterPassword = lazy(() => import('./components/passwordSetUp/EnterPassword'));
const TeamMemberList = lazy(() => import('./components/teamManagement/teamMemberList'));
const TermsAndCondition = lazy(() => import('./components/Pages/TermsAndConditions/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./components/Pages/TermsAndConditions/PrivacyPolicy'));
const ContactUs = lazy(() => import('./components/Pages/ContactUs/ContactUs'));
const KnowledgeBase = lazy(() => import('./components/Pages/KnowledgeBase/KnowledgeBase'));
const AboutUs = lazy(() => import('./components/Pages/AboutUs/AboutUs'));
const CataloguePage = lazy(() => import('./components/Pages/KnowledgeBase/CataloguePage'));
const PricingPage = lazy(() => import('./components/Pages/KnowledgeBase/PricingPage'));
const DashboardPage = lazy(() => import('./components/Pages/KnowledgeBase/DashboardPage'));
const PromotionsPage = lazy(() => import('./components/Pages/KnowledgeBase/PromotionsPage'));
const MerchandisePage = lazy(() => import('./components/Pages/KnowledgeBase/MerchandisePage'));
const AddTeamMember = lazy(() => import('./components/teamManagement/addTeamMember'));
const HomePage = lazy(() => import('./components/homePage/homePage'));
const CreateTicket = lazy(() => import('./components/ticketManagement/createTicket'));
const EditTicket = lazy(() => import('./components/ticketManagement/editTicket'));
const TicketHomePage = lazy(() => import('./components/ticketManagement/ticketHomePage'));
const Dashboard = lazy(() => import('./components/Pages/Dashboard/Dashboard'));
const RetailerSupport = lazy(() => import('./components/ticketManagement/RetailerSupportDashboard/RetailerSupport'));
const PrincipalAdmin = lazy(() => import('./components/PrincipalAdmin/principalAdminLogin'));
const PrincipalMenu = lazy(() => import('./components/PrincipalAdmin/principalMenu'));
const CatalogueDashboard = lazy(() => import('./components/ticketManagement/CatalogueDashboard/CatalogueDashboard'));
const PricingDashboard = lazy(() => import('./components/ticketManagement/PricingDashboard/PricingDashboard'));
const CatalogueCreateNewTicket = lazy(() => import('./components/ticketManagement/createTicket'));
const CatalogueEditTicket = lazy(() => import('./components/ticketManagement/editTicket'));
const PromotionsDashboard = lazy(() => import('./components/ticketManagement/PromotionsDashboard/PromotionsDashboard'));
const MyProfile = lazy(() => import('./components/myProfile/myProfile'));
const MerchandisingDashboard = lazy(() => import('./components/ticketManagement/MerchandisingDashboard/MerchandisingDashboard'));
const HelpCenterDashboard = lazy(() => import('./components/ticketManagement/HelpCenterDashboard/HelpCenterDashboard'));
const GettingStarted = lazy(() => import('./components/Pages/KnowledgeBase/GettingStarted'));

const Routes = () => {
	return (
		<Suspense fallback={ <Spinner width={100} height={100} speed={1} overlay={true} />}>
		
				<Switch>
					<PrivateRoute exact path={'/'} component={HomePage} />
					<Route exact path={'/login'} component={LoginPage} />
					<Route exact path={'/createPassword'} component={CreatePassword} />
					<Route exact path={'/otpLogin'} component={OtpLogin} />
					<Route exact path={'/resetPassword'} component={ResetPassword} />
					<Route exact path={'/forgotPassword'} component={ForgotPassword} />
					<PrivateRoute exact path={'/terms-and-conditions-acceptance'} component={PrincipalTermAndConditions} />
					<Route exact path={'/enterPassword'} component={EnterPassword} />
					<PrivateRoute exact path={'/teamMember'} component={TeamMemberList} />
					<Route exact path={'/terms-and-conditions'} component={TermsAndCondition} />
					<PrivateRoute exact path={'/contact-us'} component={ContactUs} />
					<PrivateRoute exact path={'/knowledge-base'} component={KnowledgeBase} />
					<PrivateRoute exact path={'/about-us'} component={AboutUs} />
					<Route exact path={'/knowledge-base/getting-started'} component={GettingStarted} />
					<Route exact path={'/knowledge-base/catalogue'} component={CataloguePage} />
					<Route exact path={'/knowledge-base/pricing'} component={PricingPage} />
					<Route exact path={'/knowledge-base/dashboard'} component={DashboardPage} />
					<Route exact path={'/knowledge-base/promotions'} component={PromotionsPage} />
					<Route exact path={'/knowledge-base/merchandise'} component={MerchandisePage} />
					<PrivateRoute exact path={'/homePage'} component={HomePage} />
					<PrivateRoute exact path={'/createTicket'} component={CreateTicket} />
					<PrivateRoute exact path={'/editTicket'} component={EditTicket} />
					<PrivateRoute exact path={'/ticketHomePage/:ticketType'} component={TicketHomePage} />
					<PrivateRoute exact path={'/dashboard'} component={Dashboard} />
					<PrivateRoute exact path={'/Retailer'} component={RetailerSupport} />
					<Route exact path={'/adminLogin'} component={PrincipalAdmin} />
					<AdminRoute exact path={'/principalMenu'} component={PrincipalMenu} />
					<PrivateRoute exact path={'/Catalogue'} component={CatalogueDashboard} />
					<PrivateRoute exact path={'/Pricing'} component={PricingDashboard} />
					<PrivateRoute exact path={'/createNewTicket'} component={CatalogueCreateNewTicket} />
					<PrivateRoute exact path={'/editTicket'} component={CatalogueEditTicket} />
					<PrivateRoute exact path={'/Promotions'} component={PromotionsDashboard} />
					<PrivateRoute exact path={'/addTeamMember'} component={AddTeamMember} />
					<PrivateRoute exact path={'/myProfile'} component={MyProfile} />
					<PrivateRoute exact path={'/Merchandising'} component={MerchandisingDashboard} />
					<PrivateRoute exact path={'/Help'} component={HelpCenterDashboard} />
					<Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
				</Switch>
			
		</Suspense>
	)
}

export default Routes;

