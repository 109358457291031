import { MARKETS } from "../constants/config-constants";
import env from 'react-dotenv';
import ENV from "../env";

const ConfigHelper = {
  loadConfig: async function () {
    let market = ENV && ENV.COUNTRY_CODE;
    let configFileName;
    switch (market) {
      case MARKETS.THAILAND: {
        configFileName = "config.th.json";
        break;
      }
      case MARKETS.VIETNAM: {
        configFileName = "config.vn.json";
        break;
      }
      case MARKETS.PHILIPPINES: {
        configFileName = "config.ph.json";
        break;
      }
      case MARKETS.TURKEY: {
        configFileName = "config.tr.json";
        break;
      }
      default: {
        configFileName = "config.vn.json";
      }
    }
    let filePath = "/market-configs/" + configFileName;
    const res = await fetch(filePath);
    return await res.json();
  },
};
export default ConfigHelper;
