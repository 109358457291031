import { GetNotificationStatus } from "../interfaces/GetNotificationStatus";
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";
import { updateNotificationRequest } from "../models/Requests/updateNotification1";

export function updateNotificationStatus(data: GetNotificationStatus) {
	const url = principalAPI.getAPIURL(`updateNotification`);
	let updateStatusNotification = new updateNotificationRequest(data.email, data.id, data.status);

  const result = FetchUtil.post(url, updateStatusNotification, "", "");
  const updateStatus = result.then((data) => {
    return data;
  }).catch((error) => {
    return error;
  }); 
  return updateStatus;
}
