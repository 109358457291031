import { LogoutInterface } from "../interfaces/logout";
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";
import { LogoutRequest } from '../models/Requests/logout-request';


export function logoutService (data: LogoutInterface){
  const url = principalAPI.getAPIURL('logOut');
  let validateEmailReq = new LogoutRequest(data.email,data.refresh_token,data.all);
  const result = FetchUtil.post(url, validateEmailReq, "","");
  const emailData =result.then((response : any) => {
    return response;
  }).catch((error) => {
    return error;
  }); 
  return emailData;
}
