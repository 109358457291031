import {
    GET_TEAM_MEMBERS_DATA
  } from '../../actionTypes/getTeamMembers/teamMembersActionTypes';
  
  const initialState = {
    isReloaded: false,
    teamMembersList: {}
  };
  
  const teamMembersReducers = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_TEAM_MEMBERS_DATA:
        const teamMembers: any = action.payload.data;
        return {
          ...state,
          teamMembersList: teamMembers ? teamMembers : {},
        };
      default:
        return state;
    }
  };
  
  export default teamMembersReducers;