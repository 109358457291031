import {
  UPDATE_CONFIG_DATA,
} from "../../actionTypes/configData/configDataActionTypes";

const initialState = {
  config: {}
};

const configDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CONFIG_DATA:
      const configDataValue: any = action.payload.data;
      return {
        ...state,
        config: configDataValue.config ? configDataValue.config : {},
      };
    default:
      return state;
  }
};

export default configDataReducer;
