import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ component: Component, ...rest }) => {
    const AdminUser = localStorage.getItem('adminUser') ? localStorage.getItem('adminUser') : false;
    return (
        <Route {...rest} render={props => (
            AdminUser ?
                <Component {...props} />
                : <Redirect to={{
                    pathname: "/adminLogin"
                }}
                />
        )} />
    );
};

export default AdminRoute;
