import {
  UPDATE_USER_DATA, UPDATE_USER_ACCESS_DATA, UPDATE_USER_ID_DATA, UPDATE_USER_PHOTO_DATA, UPDATE_IS_RELOADED,RESET_USER_DATA
} from '../../actionTypes/userData/userDataActionTypes';
import { userData, userAccessData, FreshUserId, ProfilePic,resetUserData } from '../../actions/userData/userDataActionData';

const initialState = {
  isReloaded: false,
  user: {},
  userAccess: []
};

const userDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      const userValue: userData = action.payload.data;
      return {
        ...state,
        user: userValue
      };
    case UPDATE_USER_ACCESS_DATA:
      const userAccessValue: userAccessData = action.payload.data;
      return {
        ...state,
        userAccess: userAccessValue
      }
    case UPDATE_USER_ID_DATA:
      const userId: FreshUserId = action.payload.data;
      const userNewUpdatedData = { ...state.user, freshUserId: userId  };
      return {
        ...state,
        user: userNewUpdatedData
      }
    case UPDATE_USER_PHOTO_DATA:
      const userProfilePic: ProfilePic = action.payload.data;
      const userUpdatedData = { ...state.user, photo_link:userProfilePic  };
      return {
        ...state,
        user: userUpdatedData
      }
    case UPDATE_IS_RELOADED:
      const isReloadedValue: boolean = action.payload.data;
      return {
        ...state,
        isReloaded: isReloadedValue
      }
      case RESET_USER_DATA:
        return {
          initialState
        };
      default:
      return state;
  }
};


export default userDataReducer;
