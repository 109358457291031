import { useTranslation } from 'react-i18next';

const useI18N = () => {
  const {t}  = useTranslation(["common", "privacy", "cookies", "tc", "faq"]);
  
  return (key: string) => {
    if (key) {
      const temp = t(key);
      return t(key)
    }
    // returning the key, will be helpful to check which key is missing.
    return key;
  };
};
export default useI18N;



