export class AddPrincipalRequest{
    email: string;
    password: string = "Principal@123";
    returnSecureToken: boolean = true;
    firstname: string;
    lastname: string = "";
    mobile: string = "";
    ipAddress: string = "";
    userAgent: string = "";
    status: string = "FirstTime";
    user_type: string = "principal_admin";
    organization_id: string;
    organization_email: string;
    org_name: string;
    country: string;
    dashBoardIds: Array <string>;
    constructor(email: string,firstname:string,organization_id: string,organization_email: string,org_name: string,country: string, dashBoardIds: Array <string>){
        this.email = email;
        this.firstname = firstname;
        this.organization_id = organization_id;
        this.organization_email = organization_email;
        this.org_name = org_name;
        this.country = country;
        this.dashBoardIds = dashBoardIds;
    }
}