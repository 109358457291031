import React, { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { NavLinkProps } from "./NavLinkProps";

const NavLinkItem: FC<NavLinkProps> = (props: NavLinkProps) => {
	const history = useHistory()
	const resetPath =() =>
	{
		history.replace({pathname:'/'})
	}
	return(
		<Link to={props.to} onClick={resetPath} className={props.active && 'active'}>{props.title}</Link>
	);
}

export default NavLinkItem;
