import { GetTeamInterface } from "../interfaces/GetTeam"
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";
import { markNotifications } from "../models/Requests/markNotifications";

export function markAllNotifications(data: GetTeamInterface) {
	const url = principalAPI.getAPIURL('markNotifications');
	let markNotification = new markNotifications(data);

  const result = FetchUtil.post(url, markNotification, "", "");
  const notificationStatus = result.then((data) => {
    return data;
  }).catch((error) => {
    return error;
  }); 
  return notificationStatus;
}
