import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";
import { AddOrganizationInterface } from "../interfaces/AddOrganization";
import { AddPrincipalInterface } from "../interfaces/AddPrincipal";
import { AddOrganizationRequest } from "../models/Requests/add-organization-request";
import { UpdateOrganizationRequest } from "../models/Requests/update-organization-request";
import { AddPrincipalRequest } from "../models/Requests/add-principal-request";
import config from "react-global-configuration";

export function getAllMarkets() {
  const url = principalAPI.getAPIURL("getAllMarkets");
  const result = FetchUtil.getByJsonType(url, "");
  const marketData = result
    .then((response: any) => {
      const markets = response["response-data"].markets;
      return markets;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}

export function getAllOrganization() {
  const url = principalAPI.getAPIURL("organization");
  const result = FetchUtil.getByJsonType(url, "");
  const marketData = result
    .then((response: any) => {
      const markets = response["response-data"].organizations;
      return markets;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}

export function getAllOrganizationCountryCode(code: number) {
  const url = principalAPI.getAPIURL(`organization?country_code=${code}`);
  const result = FetchUtil.getByJsonType(url, "");
  const marketData = result
    .then((response: any) => {
      if (response && response["response-data"].organizations) {
        const markets = response["response-data"].organizations;
        return markets;
      } else if (response && response["response-data"].status) {
        return response["response-data"].status;
      }
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}
export function getAllOrganizationById(code: number) {
  const url = principalAPI.getAPIURL(`organization?id=${code}`);
  const result = FetchUtil.getByJsonType(url, "");
  const marketData = result
    .then((response: any) => {
      const markets = response["response-data"].organizations;
      return markets;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}
const getRandomEmail = () => {
  var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var string = "";
  for (var ii = 0; ii < 15; ii++) {
    string += chars[Math.floor(Math.random() * chars.length)];
  }
  const emailAddress = string + "@autogenerated.com";
  return emailAddress;
};
export function addOrganization(data: AddOrganizationInterface) {
  const url = principalAPI.getAPIURL("addOrganization");
  let emailAddresss = null;
  if (data.email === null) {
    // emailAddresss = getRandomEmail();
    emailAddresss = null;
  } else {
    emailAddresss = data.email.toLowerCase();
  }

  let addOrganizationRequest = new AddOrganizationRequest(
    data.country_code,
    data.org_name,
    data.short_code,
    data.payment_status,
    data.content,
    data.image,
    emailAddresss,
    data.country,
    data.tier_id
  );
  const result = FetchUtil.post(url, addOrganizationRequest, "", "");
  const marketData = result
    .then((response: any) => {
      let principalRes: AddOrganizationInterface;
      principalRes = new AddOrganizationRequest(
        data.country_code,
        data.org_name,
        data.short_code,
        data.payment_status,
        data.content,
        data.image,
        data.email,
        data.country,
        data.tier_id
      );
      principalRes.userStatus = response["response-data"].status;
      return principalRes;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}
export function updateOrganization(data: AddOrganizationInterface) {
  const url = principalAPI.getAPIURL("updateOrganization");
  let addOrganizationRequest = new UpdateOrganizationRequest(
    data.payment_status,
    data.content,
    data.image,
    data.id,
    data.email,
    data.org_head_user_id,
    data.dept_id,
    data.org_name,
    data.country,
    data.tier_id
  );
  const result = FetchUtil.post(url, addOrganizationRequest, "", "");
  const marketData = result
    .then((response: any) => {
      let principalRes: AddOrganizationInterface;
      principalRes = new UpdateOrganizationRequest(
        data.payment_status,
        data.content,
        data.image,
        data.id,
        data.email,
        data.org_head_user_id,
        data.dept_id,
        data.org_name,
        data.country,
        data.tier_id
      );
      principalRes.userStatus = response["response-data"].status;
      return principalRes;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}
export function addPrincipal(data: AddPrincipalInterface) {
  const url = principalAPI.getAPIURL("accounts");
  const configGlobal = config.get('configsGlobal');
  const passwordPolicyEncoded = configGlobal.config && configGlobal.config.passwordPolicy &&  configGlobal.config.passwordPolicy.encoded ? configGlobal.config.passwordPolicy.encoded : false;
  let req = new AddPrincipalRequest(
    data.email.toLowerCase(),
    data.firstname,
    data.organization_id,
    data.organization_email,
    data.org_name,
    data.country,
    data.dashBoardIds
  );
  const result = FetchUtil.post(url, passwordPolicyEncoded ? btoa(JSON.stringify(req)) : req, "signUp", "");
  const marketData = result 
    .then((response: any) => {
      let principalRes: AddPrincipalInterface;
      principalRes = new AddPrincipalRequest(
        data.email,
        data.firstname,
        data.organization_id,
        data.organization_email,
        data.org_name,
        data.country,
        data.dashBoardIds
      );
      principalRes.userStatus = response["response-data"].status;
      return principalRes;
    })
    .catch((error) => {
      return error;
    });
  return marketData;
}
export function getConfig(){
  const url = principalAPI.getAPIURL("getIsTierEnabled");
  const result = FetchUtil.getByJsonType(url, "");
 const isTierEnabled = result
    .then((response: any) => {
      const isTierEnabled = response["response-data"].is_tier_enabled;
      return isTierEnabled;
    })
    .catch((error) => {
      return error;
    });
  return isTierEnabled;
}
export function getTiers(){
  const url = principalAPI.getAPIURL("getTiers");
  const result = FetchUtil.getByJsonType(url, "");
  
  const tierList = result
    .then((response: any) => {
      const tiers = response["response-data"].tiers;
      return tiers;
    })
    .catch((error) => {
      return error;
    });
  return tierList;
}
