import { GetTeamInterface } from "../interfaces/GetTeam"
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";

export function getDashBoardsByUserId(data: GetTeamInterface) {
  const url = principalAPI.getAPIURL(`getDashBoardsByUserId?email=${data}`);
  const result = FetchUtil.getByJsonType(url,"");
  const dashBoardList = result
    .then((response: any) => {
      if (response && response["response-data"].dashboard) {
        const dashboard = response["response-data"].dashboard;
        return dashboard;
      }
    })
    .catch((error) => {
      return error;
    });
  return dashBoardList;
}
