import {
  UPDATE_USER_DATA,
  UPDATE_USER_ACCESS_DATA,
  UPDATE_USER_ID_DATA,
  UPDATE_USER_PHOTO_DATA,
  UPDATE_IS_RELOADED,
  RESET_USER_DATA,
} from "../../actionTypes/userData/userDataActionTypes";
import {
  userData,
  userAccessData,
  FreshUserId,
  ProfilePic,
} from "./userDataActionData";

export const updateUserData = (data: userData) => {
  return {
    type: UPDATE_USER_DATA,
    payload: {
      data,
    },
  };
};

export const updateUserAccessData = (data: userAccessData) => {
  return {
    type: UPDATE_USER_ACCESS_DATA,
    payload: {
      data,
    },
  };
};

export const updateFreshUserIdData = (data: FreshUserId) => {
  return {
    type: UPDATE_USER_ID_DATA,
    payload: {
      data,
    },
  };
};

export const updateProfilePicData = (data: ProfilePic) => {
  return {
    type: UPDATE_USER_PHOTO_DATA,
    payload: {
      data,
    },
  };
};

export const updateIsReloaded = (data: boolean) => {
  return {
    type: UPDATE_IS_RELOADED,
    payload: {
      data,
    },
  };
};
export const resetUserData = () => {
  return {
    type: RESET_USER_DATA,
  };
};
