import { GetTeamInterface } from "../interfaces/GetTeam"
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";

export function getUserAccess(data: GetTeamInterface) {
  const url = principalAPI.getAPIURL(`getAccess?email=${data}`);
  const result = FetchUtil.getByJsonType(url,"");
  const accessData = result.then((response: any) => {
    let userAccessData = [];
    if (response && response.statusMessage === 'SUCCESS') {
      userAccessData = response['response-data'].access || [];
    }
    return userAccessData;
  });
  return accessData;
}

