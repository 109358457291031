import {
  UPDATE_LOGIN_DATA,
  RESET_LOGIN_DATA,
} from "../../actionTypes/login/loginActionTypes";
import { LoginData } from "../../actions/login/LoginActionData";

const initialState = {
  email: "",
};

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LOGIN_DATA:
      const bestSellerData: LoginData = action.payload.data;
      return {
        ...state,
        email: bestSellerData,
      };
    case RESET_LOGIN_DATA:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default loginReducer;
