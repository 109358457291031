import {FILTER_QUERY} from '../../actionTypes/tickets/ticketActionTypes';
  
  const initialState = {
    filterTicketQuery: "",
  };
  
  const loginReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case FILTER_QUERY:
        const query = action.payload;
        return {
          ...state,
          filterTicketQuery: query,
        };
     
      default:
        return state;
    }
  };
  
  export default loginReducer;