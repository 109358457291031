export class updateNotificationRequest {
  public email: string = "";
  public id: any;
  public status: string = "";
  constructor(email: string, id: any, status: string) {
    this.email = email;
    this.id = id;
    this.status = status;
  }
}
