import store from "../store";
//import env from 'react-dotenv';

const HTTP_METHODS = Object.freeze({
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
});

const checkValidSession = () => {
  document.location.href = "/login";
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("email");
  sessionStorage.removeItem("refreshToken");
  sessionStorage.removeItem("idToken");
};

const FetchUtil = (() => {
  let idTokenValue: any = null;
  let userEmailValue: any = null;
  let configStoreData: any = {};
  let marketIso: string = '';
  let client_id: string = '';
  let client_secret: string = '';

  const getHeaderData = () => {
    if (store && idTokenValue && idTokenValue !== "undefined" && userEmailValue) {
      const stateData = store.getState();
      configStoreData = stateData && stateData.configDataReducer.config;
      marketIso = configStoreData && configStoreData.common ? configStoreData.common.countryCode : sessionStorage.marketIso;
      client_id =  configStoreData && configStoreData.muleSoft ? configStoreData.muleSoft.clientId : sessionStorage.clientId;
      client_secret = configStoreData && configStoreData.muleSoft ? configStoreData.muleSoft.clientSecret : sessionStorage.clientSecret;
      return {
        idToken: idTokenValue,
        userEmail: userEmailValue.toLowerCase(),
        client_id: client_id, 
        client_secret: client_secret,
        country_iso: marketIso && marketIso
      };
    } else if (store) {
      const state = store.getState();
      configStoreData = state && state.configDataReducer.config;
      marketIso = configStoreData && configStoreData.common && configStoreData.common.countryCode;
      client_id =  configStoreData && configStoreData.muleSoft && configStoreData.muleSoft.clientId;
      client_secret = configStoreData && configStoreData.muleSoft && configStoreData.muleSoft.clientSecret;
      if (
        state.userDataReducer &&
        state.userDataReducer.user &&
        Object.keys(state.userDataReducer.user).length > 0 &&
        state.userDataReducer.user.idToken &&
        state.userDataReducer.user.emailId
      ) {
        idTokenValue = state.userDataReducer.user.idToken || null;
        userEmailValue = state.userDataReducer.user.emailId || null;
      } else if (
        localStorage.idToken ||
        (sessionStorage.idToken && localStorage.email) ||
        sessionStorage.email
      ) {
        idTokenValue = sessionStorage.idToken || localStorage.idToken;
        userEmailValue = localStorage.email || sessionStorage.email;
        //assuming the other values would be available in localStorage
        marketIso = sessionStorage.marketIso;
        client_id = sessionStorage.clientId;
        client_secret = sessionStorage.clientSecret;
        
      }
    }

    return {
      idToken: idTokenValue,
      userEmail: userEmailValue,
      client_id: client_id,
      client_secret: client_secret,
      country_iso: marketIso && marketIso
    };
  };

  // const defaultConfig = {};
  const handleErrors = async (response) => {
    if (response && !response.ok) {
      const errorInfo = await response.json();
      let errors = errorInfo?.errors?.errorPayload?.error?.message;
      let attemptsLeft = errorInfo?.errors?.remaining_login_attempt;
      let locked = errorInfo?.errors?.locked;
      if (errors === "INVALID_PASSWORD" && attemptsLeft !== 0) {
        return `Invalid password! You have ${attemptsLeft} attempts left`;
      } else if (errors === "MISSING_PASSWORD") {
        return "Missing Password";
      } else if (errors === "EMAIL_NOT_FOUND") {
        return "Email Not Found";
      } else if (errors === "INVALID_EMAIL") {
        return "Invalid Email ID";
      } else if (
        (errors && errors.includes("TOO_MANY_ATTEMPTS_TRY_LATER")) ||
        locked === "yes"
      ) {
        return "You have entered an incorrect password too many times. Your account has been temporarily locked. You will be able to log in after 30 mins";
      } else if (errors === undefined) {
        return errorInfo;
      } else {
        return errors;
      }
    }
  };
  
  const request = (
    url: string,
    method: string,
    body: any,
    action: string,
    userType: string
  ) => {
    const headerConfig = {
      action: action,
      userType: userType
    };
    const defaultConfigs = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getHeaderData(),
    };
    const headers = new Headers({
      ...defaultConfigs,
      ...headerConfig,
    });
    const requestConfig = {
      method,
      headers,
      mode: "cors",
    };
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      requestConfig.body = JSON.stringify(body);
    }
    const req = new Request(url, requestConfig);

    // TODO:: abort the previous request if new request is coming for same functionality e.g. auto suggest
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else {
              // throw new Error(response.errors.errorPayload.errors[0]);
              // response.json().then((error) => {
              //   reject(error.errors.errorPayload.error.errors[0]);
              // })
              const error = handleErrors(response);
              reject(error);
            }
          }
        })
        .then(
          (data) => {
            if (data) {
              if (
                data &&
                data["response-data"] &&
                data["response-data"].status === "Logged out"
              ) {
                idTokenValue = null;
              } else if (data && data.status === "Session Invalid") {
                checkValidSession();
              }
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const requestGetSimple = (url: string) => {
    const headerConfig = {
      token: "token", //TO DO write a key based helper method to get the token from sessionStorage or localStorage
      email: "email", ///TO DO write a helper method to Get the email from store...
    };
    const defaultConfigs = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getHeaderData(),
    };
    const headers = new Headers({
      ...defaultConfigs,
      ...headerConfig,
    });
    const method = "GET";
    const requestConfig = {
      method,
      headers,
      mode: "cors",
    };

    const req = new Request(url, requestConfig);

    // TODO:: abort the previous request if new request is coming for same functionality e.g. auto suggest
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else {
              // throw new Error(response.errors.errorPayload.errors[0]);
              // response.json().then((error) => {
              //   reject(error.errors.errorPayload.error.errors[0]);
              // })
              const error = handleErrors(response);
              reject(error);
            }
          }
        })
        .then(
          (data) => {
            if (data) {
              if (data && data.status === "Session Invalid") {
                checkValidSession();
              }
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const requestGet = (url: string, method: string) => {
    const defaultConfigs = {
      Accept: "application/csv",
      "Content-Type": "application/csv",
      ...getHeaderData(),
    };
    const headers = new Headers({
      ...defaultConfigs,
    });
    const requestConfig = {
      method,
      headers,
      mode: "cors",
    };
    const req = new Request(url, requestConfig);

    // TODO:: abort the previous request if new request is coming for same functionality e.g. auto suggest
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.text();
            } else {
              // throw new Error(response.errors.errorPayload.errors[0]);
              // response.json().then((error) => {
              //   reject(error.errors.errorPayload.error.errors[0]);
              // })
              const error = handleErrors(response);
              reject(error);
            }
          }
        })
        .then(
          (data) => {
            if (data) {
              if (data && data.status === "Session Invalid") {
                checkValidSession();
              }
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  const requestFormData = (
    url: string,
    method: string,
    body: any,
    action: string,
    userType: string
  ) => {
    const headerConfig = {
      action: action,
      userType: userType,
    };
    const headers = new Headers({
      ...getHeaderData(),
      ...headerConfig,
    });
    const requestConfig = {
      method,
      headers,
      mode: "cors",
    };
    var bodyFormData = new FormData();
    if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
      bodyFormData.append("createTicketDetails", JSON.stringify(body));
      requestConfig.body = bodyFormData;
    }
    const req = new Request(url, requestConfig);

    // TODO:: abort the previous request if new request is coming for same functionality e.g. auto suggest
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else {
              // throw new Error(response.errors.errorPayload.errors[0]);
              // response.json().then((error) => {
              //   reject(error.errors.errorPayload.error.errors[0]);
              // })
              const error = handleErrors(response);
              reject(error);
            }
          }
        })
        .then(
          (data) => {
            if (data) {
              if (data && data.status === "Session Invalid") {
                checkValidSession();
              }
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  };
  
  const CreateTicketByFormData = (
    url: string,
    method: string,
    body: any,
    action: string,
    userType: string
  ) => {
    const headerConfig = {
      action: action,
      userType: userType,
    };
    const headers = new Headers({
      ...getHeaderData(),
      ...headerConfig,
    });
    const requestConfig = {
      method,
      headers,
      mode: "cors",
    };
    // var bodyFormData = new FormData();
    // if (method === HTTP_METHODS.POST || method === HTTP_METHODS.PUT) {
    // bodyFormData.append("attachment", body);
    requestConfig.body = body;
    // }

    const req = new Request(url, requestConfig);

    // TODO:: abort the previous request if new request is coming for same functionality e.g. auto suggest
    return new Promise((resolve, reject) => {
      fetch(req)
        .then((response) => {
          if (response) {
            if (
              response.ok &&
              response.status >= 200 &&
              response.status <= 299
            ) {
              return response.json();
            } else {
              // throw new Error(response.errors.errorPayload.errors[0]);
              // response.json().then((error) => {
              //   reject(error.errors.errorPayload.error.errors[0]);
              // })
              const error = handleErrors(response);
              reject(error);
            }
          }
        })
        .then(
          (data) => {
            if (data) {
              if (data && data.status === "Session Invalid") {
                checkValidSession();
              }
              resolve(data);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  };

  return {
    postForFormData: (
      url: string,
      body: any,
      action: string,
      userType: string
    ) => {
      return requestFormData(url, HTTP_METHODS.POST, body, action, userType);
    },
    createTicketForFormData: (
      url: string,
      body: any,
      action: string,
      userType: string
    ) => {
      return CreateTicketByFormData(
        url,
        HTTP_METHODS.POST,
        body,
        action,
        userType
      );
    },
    post: (url: string, body: any, action: string, userType: string) => {
      return request(url, HTTP_METHODS.POST, body, action, userType);
    },
    get: (url: string) => {
      return requestGet(url, HTTP_METHODS.GET);
    },
    getByJsonType: (url: string, headerConfig: any) => {
      return request(url, HTTP_METHODS.GET, headerConfig);
    },
    getSimple: (url: string) => {
      return requestGetSimple(url);
    },
    put: (url: string, headerConfig: any, body: any) => {
      return request(url, HTTP_METHODS.PUT, headerConfig, body);
    },
    delete: (url, headerConfig) => {
      return request(url, HTTP_METHODS.DELETE, headerConfig);
    },
  };
})();

export default FetchUtil;
