// Action type to update user data
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

// Action type to update user access data
export const UPDATE_USER_ACCESS_DATA = 'UPDATE_USER_ACCESS_DATA';

export const UPDATE_USER_ID_DATA = 'UPDATE_USER_ID_DATA';

export const UPDATE_USER_PHOTO_DATA = 'UPDATE_USER_PHOTO_DATA';

export const UPDATE_IS_RELOADED = 'UPDATE_IS_RELOADED';

export const RESET_USER_DATA = 'RESET_USER_DATA';
