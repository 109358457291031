export class AddOrganizationRequest{
    public description: string="";
    public country_code: string="";
    public org_name: string=""
    public short_code: string=""
    public payment_status: string=""
    public content: string=""
    public image: string =""
    public email: string =""
    public country: string =""
    public tier_id: string= ""
    constructor(country_code: string,org_name: string,short_code:string,payment_status: string,content: string,image: string,email: string,country: string,tierId: string){
        this.country_code = country_code;
        this.org_name = org_name;
        this.short_code = short_code;
        this.payment_status = payment_status;
        this.content = content;
        this.image = image;
        this.email = email;
        this.country = country;
        this.tier_id = tierId;
    }
}