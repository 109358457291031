import React, {FC} from "react";
import { logoProps } from "./logoProps";
import './logo.scss';

const Logo: FC<logoProps> = (props: logoProps) => {
  return (
    <div className={"logoContainer"}>
      <img
        className="d-md-block"
        src={props.logo}
        alt='Gro 24/7 Logo'
      />
    </div>
  );
};

export default Logo;
