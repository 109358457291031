import {IPrincipal} from '../interfaces/IPrincipal.js';
import {LoginStatus} from '../enums/principal-enum';

export class Principal implements IPrincipal{
    public emailId: string="";
    public userStatus: LoginStatus = LoginStatus.NotFound;
    idToken: string="";
    refreshToken: string="";
    password: string="";
    returnSecureToken: boolean = true;
    userType: string="";
    expiresIn: string="";
    freshGroupId: string="";
    freshUserId: string="";
    kind: string="";
    localId:string="";
    principalId: string="";
    displayName: string="";
    content: string="";
    image: string="";
    photo_link: string="";
    market_name: string="";
    organization_id: string="";
    organization_name: string="";
    department_id:string="";
    freshAgentGroupId: string="";
    freshCountryId: string="";
    constructor(emailIdIn: string,password:string){
        this.emailId = emailIdIn;
        this.password = password;
    }
}