import {
    GET_TEAM_MEMBERS_DATA
  } from '../../actionTypes/getTeamMembers/teamMembersActionTypes';
  
  export const getTeamMembersData = (data: any) => {
    return {
        type: GET_TEAM_MEMBERS_DATA,
        payload: {
            data
        }
    }
  }
  