import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";

export function getAccessCategory() {
  const url = principalAPI.getAPIURL('getAccessCategory');
  const result = FetchUtil.getByJsonType(url,"");
  const data = result.then((response: any) => {
    let AccessCatData=[];
    if (response['statusMessage'] === 'SUCCESS') {
      AccessCatData = response['response-data'].category ? response['response-data'].category : [];
      AccessCatData && AccessCatData.length > 0 && AccessCatData.map((ele: any) => {
        ele['accessValue'] = 'na';
      });
    }
    return AccessCatData;
  });
  return data;
}
