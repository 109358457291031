import { GetTeamInterface } from "../interfaces/GetTeam"
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";

export function retrievePrincipalsByOrgId(data: GetTeamInterface) {
  const url = principalAPI.getAPIURL(`retrieveAllPrincipal?org_id=${data}`);
  const result = FetchUtil.getByJsonType(url,"");
  const listOfPrincipals = result.then((response: any) => {
    if(response.statusMessage === "SUCCESS" && response['response-data'].status === "Success"){
      return response['response-data'].principals;
    }else{
      console.log(response);
      return [];
    }
  });
  return listOfPrincipals;
}