import React, { useMemo, useEffect, useState } from "react";
import { BrowserRouter, Router, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { ReLoginRequest } from "./models/Requests/re-login";
import { ReLogin } from "./interfaces/ReLogin";
import { reLoginService } from "./services/reLogin";
import { retrievePrincipalsByOrgId } from "./services/retrievePrincipalsByOrgId";
import { updateUserData } from "./redux/actions/userData/userDataActions";
import { updateIsReloaded } from "./redux/actions/userData/userDataActions";
import { getTeamMembersData } from "./redux/actions/getTeamMembers/teamMembersActions";
import { updatePrincipalsList } from "./redux/actions/listOfPrincipals/listOfPrincipalActions";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Routes from "./route";
import Spinner from "./components/Spinner/spinner";
import { useHistory } from "react-router-dom";
import { LoginStatus } from "./enums/principal-enum";
import { getTeamMember } from "./services/getTeamMember";
import { GetTeamInterface } from "./interfaces/GetTeam";

export const ROUTES_WITHOUT_HEADER = [
  "/login",
  "/enterPassword",
  "/forgotPassword",
  "/logout",
  "/adminLogin",
  "/principalMenu",
  "/createPassword",
  "/terms-and-conditions",
  "/privacy-policy",
  "/terms-and-conditions-acceptance",
];
export const ROUTES_WITHOUT_FOOTER = [
  "/login",
  "/enterPassword",
  "/forgotPassword",
  "/logout",
  "/adminLogin",
  "/principalMenu",
  "/createPassword",
  "/privacy-policy",
  "/terms-and-conditions-acceptance",
];
export const ROUTES_WITHOUT_GRAY_BACKGROUND = [
  "/",
  "/homePage",
  "/terms-and-conditions-acceptance",
  "/adminLogin",
  "/principalMenu",
  "/login",
  "/createPassword",
  "/enterPassword",
  "/forgotPassword",
];

const Main = (props: any) => {
  const {
    updateUserDataFx,
    updateIsReloadedFx,
    updatePrincipalsList,
    getTeamMembersDataFx,
    isReloaded,
  } = props;

  const { pathname } = useLocation();
  const [isUnMount, setIsUnMount] = useState(true);
  let history = useHistory();
  // const hasBackGround = ROUTES_WITHOUT_GRAY_BACKGROUND.some(route =>
  //   route instanceof RegExp ? route.test(pathname) : pathname.search(route) !== -1
  // )

  const hasBackGround = ROUTES_WITHOUT_GRAY_BACKGROUND.includes(pathname);
  const MainContinerColor = hasBackGround ? "homepageColor" : "pageClass";
  const { hasHeader, hasFooter } = useMemo(() => {
    console.log("pathname", pathname);
    return {
      hasHeader: !ROUTES_WITHOUT_HEADER.some((route) =>
        route instanceof RegExp
          ? route.test(pathname)
          : pathname.toString().toLocaleLowerCase().search(route.toString().toLocaleLowerCase()) !== -1
      ),
      hasFooter: !ROUTES_WITHOUT_FOOTER.some((route) =>
        route instanceof RegExp
          ? route.test(pathname)
          : pathname.toString().toLocaleLowerCase().search(route.toString().toLocaleLowerCase()) !== -1
      ),
    };
  }, [pathname]);

  useEffect(() => {

    const ReLogin = async () => {
      let principalReq: ReLogin;
      updateIsReloadedFx(true);
      principalReq = new ReLoginRequest(
        localStorage.refreshToken || sessionStorage.refreshToken
      );
      const result = await reLoginService(principalReq);
      await updateUserDataFx(result);
      if(result && result.userStatus === LoginStatus.UserDoesNotExists || result.userStatus === LoginStatus.SessionInvalid) {
        history.push({
          pathname: "/login",
        });
      }
      if(result && result.organization_id){
        const listOfPrincipals = await retrievePrincipalsByOrgId(
          result.organization_id
        );
        listOfPrincipals && updatePrincipalsList(listOfPrincipals);
      }
      updateIsReloadedFx(false);
    }

    const getTeamData = async ()=>{
    let data = [];
    const email =
    (localStorage.getItem("email")
    ? localStorage.getItem("email")
    : sessionStorage.getItem("email")) || "";
    let principalReq: GetTeamInterface;
    principalReq = email;
    if(isUnMount){
    const result = await getTeamMember(principalReq);
    if (result && result.statusMessage === 'SUCCESS' && result['response-data'].team) {
      data = result['response-data'].team;
      getTeamMembersDataFx(data);
    }
  }
  }
    // adminUser will set if admin logged in, and relogins and getTeamMember is only for normal login
    const AdminUser = localStorage.getItem('adminUser') ? localStorage.getItem('adminUser') : false;
   
    if (!AdminUser) {
      // Relogin API will be called based on below conditions.
      // Page reload type.
      if (performance.navigation.type === 1) {
        ReLogin();
      }

      // Open page in new tab.
      else if (performance.navigation.type === 0) {
        if ((localStorage.refreshToken || sessionStorage.refreshToken) && localStorage.isStayLogin === 'true') {
          ReLogin();
          if (window.location.href.split('/')[3] === LoginStatus.LoginUrlCheck) {
            history.push({
              pathname: "/homePage",
            });
          }
        } else if ((localStorage.refreshToken || sessionStorage.refreshToken) && localStorage.isStayLogin === 'false') {
          localStorage.removeItem('isStayLogin');
          history.push({
            pathname: "/login",
          });
        }
      }

      getTeamData();
    }

    return(()=>{
      setIsUnMount(false);
    })
  }, []);

  return (
    <>
      {hasHeader ? <Header title={""} activePage={pathname} /> : null}
      {isReloaded ? (
        <Spinner width={100} height={100} speed={1} overlay={true} />
      ) : (
        <div className={`${MainContinerColor && MainContinerColor}`}>
          <Routes />
        </div>
      )}
      {hasFooter ? <Footer /> : null}
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isReloaded: state.userDataReducer.isReloaded,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUserDataFx(data) {
      dispatch(updateUserData(data));
    },
    updateIsReloadedFx(data) {
      dispatch(updateIsReloaded(data));
    },
    updatePrincipalsList(data) {
      dispatch(updatePrincipalsList(data));
    },
    getTeamMembersDataFx(data){
     dispatch(getTeamMembersData(data));
   }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
