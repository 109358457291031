import {
    UPDATE_LOGIN_DATA,
    RESET_LOGIN_DATA
} from '../../actionTypes/login/loginActionTypes';
import { LoginData } from './LoginActionData';

export const updateLoginData = (data: LoginData) => {
    return {
        type: UPDATE_LOGIN_DATA,
        payload: {
            data
        }
    }
};
export const resetLoginData = () => {
    return {
        type: RESET_LOGIN_DATA,
    }
};