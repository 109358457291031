import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//import Backend from 'i18next-http-backend';
//import XHR from 'i18next-xhr-backend';
import enCommon from './locales/en/common.json';
import enCookies from './locales/en/cookies.json';
import enPrivacy from './locales/en/privacy.json';
import enTC from './locales/en/tc.json';
import enFaq from './locales/en/faq.json';
import vnCommon from './locales/vn/common.json';
import vnCookies from './locales/vn/cookies.json';
import vnPrivacy from './locales/vn/privacy.json';
import vnTC from './locales/vn/tc.json';
import vnFaq from './locales/vn/faq.json';

import trCommon from './locales/tr/common.json';
import trCookies from './locales/tr/cookies.json';
import trPrivacy from './locales/tr/privacy.json';
import trTC from './locales/tr/tc.json';
import trFaq from './locales/tr/faq.json';

import phCommon from './locales/ph/common.json';
import phCookies from './locales/ph/cookies.json';
import phPrivacy from './locales/ph/privacy.json';
import phTC from './locales/ph/tc.json';
import phFaq from './locales/ph/faq.json';

import thCommon from './locales/th/common.json';
import thCookies from './locales/th/cookies.json';
import thPrivacy from './locales/th/privacy.json';
import thTC from './locales/th/tc.json';
import thFaq from './locales/th/faq.json';


i18n
    //.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: true,
      fallbackLng: 'en',
      lng: i18n.options.lng,
      nsSeparator: ":",
      //keySeparator: "false",
      resources: {
        en: {
          common: enCommon,
          cookies: enCookies,
          privacy: enPrivacy,
          tc: enTC,
          faq: enFaq
        },
        vn: {
          common: vnCommon,
          cookies: vnCookies,
          privacy: vnPrivacy,
          tc: vnTC,
          faq: vnFaq
        },
        tr: {
          common: trCommon,
          cookies: trCookies,
          privacy: trPrivacy,
          tc: trTC,
          faq: trFaq
        },
        ph: {
          common: phCommon,
          cookies: phCookies,
          privacy: phPrivacy,
          tc: phTC,
          faq: phFaq
        },
        th: {
          common: thCommon,
          cookies: thCookies,
          privacy: thPrivacy,
          tc: thTC,
          faq: thFaq
        },
      },
      //defaultNS: "common",
      detection: {
        order: ["localStorage", "navigator"],
        lookupQuerystring: "lng",
        lookupLocalStorage: "I18N_LANGUAGE",
        caches: ["localStorage"]
      },
      interpolation: {
        // react already safes from xss
        escapeValue: false
      }  
      // backend: {
      //   //loadPath: '/i18n/{{lng}}/{{ns}}.json'
      //   //loadPath: '/locales/en-VN.json'
      //   loadPath: '/locales/{{lng}}.json'
      // }
    });


export default i18n;