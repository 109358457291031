import React, { useEffect, useState } from "react";
import "./App.scss";
import Main from "./main";
import { BrowserRouter } from "react-router-dom";
import WebEngageHelper from "./helper/webEngage-helper";
import { useTranslation } from "react-i18next";
import store from "./store";
import config from "react-global-configuration";
import ConfigHelper from "./helper/config-helper";
import { useSelector, useDispatch } from "react-redux";
import { updateConfigData } from "./redux/actions/configData/configDataActions";
import Spinner from "./components/Spinner/spinner";

function App() {
  const [keepSignIn, setKeepSignIn] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  //const selectedLang = useSelector(state => state.selectedLanguageReducer.selectedLang);
  const selectedLang = useSelector(
    (state) => store.getState().selectedLanguageReducer.selectedLang
  );

  useEffect(() => {
    const lng = localStorage.getItem("I18N_LANGUAGE")
      ? localStorage.getItem("I18N_LANGUAGE")
      : "en";
    //i18n.changeLanguage(lng);
    i18n.changeLanguage(lng).then(() => {
      //this.props.close();
      i18n.options.lng = lng;
    });
  }, [selectedLang]);

  const marketSpecificConfig = () => {
    let configs = ConfigHelper.loadConfig().then((data) => {
      config.set({ configsGlobal: data }, { freeze: false });
      sessionStorage.setItem("marketIso", data.config.common.countryCode);
      sessionStorage.setItem("clientId", data.config.muleSoft.clientId);
      sessionStorage.setItem("clientSecret", data.config.muleSoft.clientSecret);
      sessionStorage.setItem("baseUrl", data.config.muleSoft.baseUrl);
      sessionStorage.setItem(
        "baseTicketUrl",
        data.config.muleSoft.baseTicketUrl
      );
      sessionStorage.setItem(
        "baseTicketUrl",
        data.config.webEngage.licenceCode
      );
      dispatch(updateConfigData(data));
      WebEngageHelper.init();
      setKeepSignIn(true);
    });
  };

  //Loading the market specific config
  useEffect(() => {
    marketSpecificConfig();
  }, []);

  return (
    // <I18nextProvider i18n={i18n}>
    // <Suspense fallback="loading...">
    <BrowserRouter>
      {keepSignIn ? (
        <Main />
      ) : (
        <Spinner width={100} height={100} speed={1} overlay={true} />
      )}
    </BrowserRouter>
    // </Suspense>
    /* </I18nextProvider> */
  );
}
export default App;
