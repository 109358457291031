import {
  UPDATE_ACCESS_CAT_DATA
} from '../../actionTypes/accessCategory/accessCategoryActionTypes';
import { AccessCategoryData } from './accessCategoryActionData';

export const updateAccessCategoryData = (data: AccessCategoryData) => {
  return {
    type: UPDATE_ACCESS_CAT_DATA,
    payload: {
      data
    }
  }
};