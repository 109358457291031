import  React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import config from "react-global-configuration";
//import '../../i18n';
//import countryUk from "../../assets/images/country-uk.svg";
//import countryVn from "../../assets/images/country-vn.svg";
import { updateSelectedLanguage } from "../../redux/actions/selected-language/selected-language-actions";

const LanguageSelector: React.FC = (props) => {
    const {
        updateSelectedLanguage
      }: any = props;
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState({});
    const configGlobal = config.get('configsGlobal');
    // const langList = [
    //     { key: "en", value: "English", image:'/images/country-uk.svg', langCode:'en'},
    //     { key: "vn", value: "Vietnamese", image:'/images/country-vn.svg', langCode:'vn'}
    //   ];

    // debugger;
    const langList = configGlobal.config.language.countries;
    const handleSelect = async (key, event, langCode) => {
        //setSelected({ key, value: event.target.text });
        setSelected({ key, value: event.target.attributes['image'].nodeValue });
        const lng = event.target.attributes['langcode'].nodeValue;
        localStorage.setItem('I18N_LANGUAGE', lng);
        await updateSelectedLanguage(lng);
        //i18n.changeLanguage(lng);
    };

    return (
        <DropdownButton
            id="dropdown-basic-button"
            variant="info"
            className="floatRight"
            onSelect={handleSelect}
            //title={selected?.value || langList[0].value}
            title={<img src={selected?.value || langList[0].image} />}
      >
            {langList.map((item, index) => {
            return (
                <Dropdown.Item key={index} eventKey={item.key} langCode={item.langCode} image={item.image}>
                    <img src={item.image} />
                    {item.key}
                </Dropdown.Item>
            );
            })}
        </DropdownButton>
    );
  };

  const mapStateToProps = (state) => {
    return {
      selectedLanguage: state.selectedLanguageReducer.selectedLang
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
        updateSelectedLanguage(data) {
        dispatch(updateSelectedLanguage(data));
      }
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
