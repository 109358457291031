import {
  UPDATE_LIST_OF_PRINCIPALS
} from "../../actionTypes/listOfPrincipals/listOfPrincipalActionTypes";
import {
  principalData
} from "./listOfPrincipalActionData";

export const updatePrincipalsList = (data: principalData) => {
  return {
    type: UPDATE_LIST_OF_PRINCIPALS,
    payload: {
      data,
    },
  };
};