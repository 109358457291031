import { config } from 'process';
import React from 'react';
import store from "../store";

const WebEngageHelper = {
  logIn: function (userIdentifuer) {
    window.webengage.user.login(userIdentifuer);
  },
  setAttributes: function (attributeText, val) {
    window.webengage.user.setAttribute(attributeText, val);
  },
  track: function (trackText, data) {
    console.log("we", data);
    window.webengage.track(trackText, data);
  },
  trackObj: function (trackText, data) {
    //let weData = JSON.stringify(data).slice(1, -1);
    let weData = JSON.stringify(data);

    //weData = weData.replace(/"([^"]+)":/g, '$1:');//regex to eliminate the quote on the keys
    console.log("weo", weData);
    window.webengage.track(trackText, weData);
  },
  logout: function () {
    window.webengage.user.logout();
  },

  getConfigData: function() {
    let configStoreData: any = {};
    if(store) {
      const state = store.getState();
      configStoreData = state && state.configDataReducer.config;
    }
    return configStoreData;
  },

  init: function () {
    let configData = this.getConfigData();
    let licenseCode = configData && configData.webEngage ? configData.webEngage.licenceCode : sessionStorage.weLicenseCode; 
    var scriptWeInit = document.createElement('script');
    this.loadWEScripts(scriptWeInit);
    scriptWeInit.addEventListener("load", () => {
      window.webengage.init(licenseCode);
      console.log("webengage initiated");
    });
  },

  loadWEScripts: function (scriptWeInit) {

    scriptWeInit.type = 'text/javascript';
    scriptWeInit.id = "_webengage_script_tag";
    scriptWeInit.src = '/scripts/webengage-init.js';
    scriptWeInit.async = false;
    scriptWeInit.defer = true;
    document.body.appendChild(scriptWeInit);

  }
}
export default WebEngageHelper;