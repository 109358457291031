import {
  UPDATE_LIST_OF_PRINCIPALS
} from '../../actionTypes/listOfPrincipals/listOfPrincipalActionTypes';
import { principalData } from '../../actions/listOfPrincipals/listOfPrincipalActionData';

const initialState = {
  principalList: []
};

const listOfPrincipalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_LIST_OF_PRINCIPALS:
      const principalsData: principalData = action.payload.data;
      return {
        ...state,
        principalList: principalsData
      };
    default:
      return state;
  }
};


export default listOfPrincipalReducer;