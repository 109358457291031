import {
    GET_DASHBOARDTYPES_BY_PRINCIPAL
  } from '../../actionTypes/dashBoardByUserId/dashBoardByUserIdActionTypes';
  import { DashBoardTypeData } from './dashBoardByUserIdActionsData';
  
  export const updateDashBoardTypes = (data: DashBoardTypeData) => {
    return {
      type: GET_DASHBOARDTYPES_BY_PRINCIPAL,
      payload: {
        data
      }
    }
  };