export enum LoginStatus {
  NotFound = "NotFound",
  FirstTime = "FirstTime",
  Returning = "Recurring",
  Active = "Active",
  LogOut = "Logged out",
  TCPending = "TCPending",
  Success = "Success",
  MaxExceed = "Maximum number exceeded",
  NoSession = "No Session Record Found",
  Error = "Error",
  NoRecordFound="No Record Found",
  ProfileLocked="User Profile is Locked",
  UserDoesNotExists="User does not exists",
  SessionInvalid="Session Invalid",
  LoginUrlCheck="login"
}

export enum UserType {
  PRINCIPAL_ADMIN = "principal_admin",
  PRINCIPAL = "principal",
}

export enum Notifications {
  TITLE = "Notifications",
  MARK_ALL_READ = "Mark all as read",
  INVITATION_TYPE = "invitation_accepted",
  ACCEPTED_INVITATION = "Say hello and welcome Emílio to the team.",
}

export enum TicketStatus {
  OPEN = 2,
  PENDING = 3,
  RESPONSE_NEEDED = 7,
  RESOLVED = 4,
  CLOSED=5,
  APPROVED = 6
}

export enum TeamMemberStatus {
  ACTIVE = "Active",
  FIRSTTIME = "FirstTime",
  PENDING = "Pending",
  INVALID = "Invalid",
  DEACTIVE = "Deactive",
  INACTIVE = "Inactive"
}
export enum PrincipleMemberStatus {
  ACTIVE = "Active",
  FIRSTTIME = "Created",
  PENDING = "Yet to accept T&C",
  INVALID = "Invalid",
  DEACTIVE = "Deactivated",
  INACTIVE = "Inactive"
}

export enum AccessTypes {
  e = "Edit",
  na = "No Access",
  v = "View",
  Dashboard = "Dashboard"
}

export enum BannerDropDownTitle {
  DropDownTitle = "Create a case"
}

export enum Category {
  CATALOGUE = 'Catalogue',
  PRICING= 'Pricing',
  PROMOTIONS='Promotions',
  MERCHANDISING ='Merchandising',  
  RETAILERSUPPORT='Retailer support',
  HELPCENTER='Helpcenter',
  DASHBOARD='Dashboard',
  DASHBOARDS='Dashboards',
  GETTING_STARTED='Getting started'
}

export enum ThreeCards {
  THREE_CARDS = 3
}

export enum TimeIntervelForRefetchTickets {
  TIME_INTERVAL = 60000
}
export enum PriorityOfTicket {
  LOW= 1,
  MEDIUM=2,
  HIGH=3,
  URGENT=4

}

export enum DashboardTierEnabled {
  ZERO = "0",
  ONE = "1"

}