import {
    GET_DASHBOARDTYPES_BY_PRINCIPAL
  } from '../../actionTypes/dashBoardByUserId/dashBoardByUserIdActionTypes';
  import { DashBoardTypeData } from '../../actions/dashBoardByUserId/dashBoardByUserIdActionsData';
  
  const initialState = {
    dashBoardTypes: []
  };
  
  const dashBoardTypeReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_DASHBOARDTYPES_BY_PRINCIPAL:
        const catData: DashBoardTypeData = action.payload.data;
        return {
          ...state,
          dashBoardTypes: catData
        };
      default:
        return state;
    }
  };
  
  export default dashBoardTypeReducer;
  