import { GetTeamInterface } from "../interfaces/GetTeam"
import FetchUtil from "../utils/fetch-util";
import principalAPI from "../utils/principal-util";

export function getNotifications(data: GetTeamInterface) {
  const url = principalAPI.getAPIURL(`retrieveNotification?email=${data}`);
  const result = FetchUtil.getByJsonType(url,"");
  const teamData = result.then((response: any) => {
    return response;
  });
  return teamData;
}
