export class UpdateOrganizationRequest{
    public payment_status: string=""
    public content: string=""
    public image: string =""
    public id: string =""
    public email: string =""
    public org_head_user_id: string =""
    public dept_id: string =""
    public org_name: string=""
    public country: string =""
    public tier_id: string= ""
    constructor(payment_status: string,content: string,image: string,id: string,email: string,org_head_user_id: string, dept_id: string, org_name: string,country: string,tierId: string){
        this.payment_status = payment_status;
        this.content = content;
        this.image = image;
        this.id = id;
        this.org_head_user_id = org_head_user_id;
        this.email = email;
        this.dept_id = dept_id;
        this.org_name = org_name;
        this.country = country;
        this.tier_id = tierId;
    }
}