import {
    SELECTED_LANGUAGE_CHANGED
} from '../../actionTypes/selected-language/selected-language-acion-types';

export const updateSelectedLanguage = (selectedLang: string) => {
    return {
        type: SELECTED_LANGUAGE_CHANGED,
        payload: {
            selectedLang
        }
    }
};