import React, { lazy, Suspense } from "react";
import "./spinner.scss";
import animationData from "./loader.json";

// Loading indicator
const Lottie = lazy(() => import(/* webpackChunkName: 'Lottie' */ /* webpackPreload: true */ 'react-lottie-light'));
const Spinner = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      progressiveLoad: true,
    },
    animationData,
  };
  const { width = 400, height = 400, speed = 1, overlay = false } = props;
  const renderSpinner = () => {
    if (overlay) {
      return (
        <div className={"overlay"}>
          <div className={"overlay-inner"}>
            <Suspense fallback={null}>
              <Lottie
                options={defaultOptions}
                height={height}
                width={width}
                speed={speed}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: () => console.log("the animation completed:"),
                  },
                ]}
              />
            </Suspense>
          </div>
        </div>
      );
    } else {
      return (
        <Suspense fallback={null}>
          <Lottie
            options={defaultOptions}
            height={height}
            width={width}
            speed={speed}
            eventListeners={[
              {
                eventName: "complete",
                callback: () => console.log("the animation completed:"),
              },
            ]}
          />
        </Suspense>
      );
    }
  };
  return renderSpinner();
};

export default Spinner;
