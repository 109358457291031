import {
  UPDATE_CONFIG_DATA,
} from '../../actionTypes/configData/configDataActionTypes';

export const updateConfigData = (data: any) => {
  return {
      type: UPDATE_CONFIG_DATA,
      payload: {
          data
      }
  }
}