import React, { FC, useState, useEffect } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { HeaderProps } from "./HeaderProps";
import { useHistory } from "react-router-dom";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import { DashboardTierEnabled, Notifications } from "../../enums/principal-enum";
import moment from 'moment';
import "./Header.scss";
import {MARKETS} from '../../constants/config-constants';

import { GetTeamInterface } from "../../interfaces/GetTeam";
import { GetNotificationStatus } from "../../interfaces/GetNotificationStatus";
import { getNotifications } from "../../services/getNotifications";
import { markAllNotifications } from "../../services/markAllNotifications";
import { updateNotificationStatus } from "../../services/updateNotificationStatus";
import { updateNotificationRequest } from "../../models/Requests/updateNotification1";
import WebEngageHelper from '../../helper/webEngage-helper';

/* Image assets */
import groLogo from "../../assets/images/gro247_logo.svg";

import notificationIcon from "../../assets/images/notifications-icon.svg";
import profileAvatar from "../../assets/images/headerDefaultProfilePic.svg";
import userIcon from "../../assets/images/user-icon.svg";
import teamIcon from "../../assets/images/team-icon.svg";
import logoutIcon from "../../assets/images/logout-icon.svg";
import { logoutService } from "../../services/logOut";
import { LogoutInterface } from "../../interfaces/logout";
import { LogoutRequest } from "../../models/Requests/logout-request";
import { LoginStatus } from "../../enums/principal-enum";
import { useSelector, useDispatch } from "react-redux";
import { getUserAccess } from "../../services/getUserAccess";
import { getAccessCategory } from "../../services/getAccessCategory";
import { getDashBoardsByUserId } from "../../services/getDashBoardsByUserId";
import { getConfig } from "../../services/getAllMarkets";
import { updateUserAccessData,resetUserData } from "../../redux/actions/userData/userDataActions";
import { updateAccessCategoryData } from "../../redux/actions/accessCategory/accessCategoryActions";
import { updateDashBoardTypes } from "../../redux/actions/dashBoardByUserId/dashBoardByUserIdActions";
import { resetLoginData } from "../../redux/actions/login/loginActions";
import { Category } from '../../enums/principal-enum'
import  LanguageSelector from '../language-selector/language-selector'
//import { useTranslation, withTranslation } from 'react-i18next';
import useI18N from '../../helper/i18nHelpers';

/* List of links */
const headerPrimaryLinks = [
  { id: 1, title: "header.home", to: "homePage", active: true },
  { id: 7, title: "header.help", to: "Help", active: false },
  { id: 9, title: "header.knowledgebase", to: "knowledge-base", active: false },
];

const ROUTES_WITHOUT_GRAY_BACKGROUND = [
  '/',
  '/homePage',
  '/terms-and-conditions-acceptance',
  '/adminLogin',
  '/principalMenu',
  '/login',
  '/createPassword',
  '/enterPassword',
  '/forgotPassword'
]

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const i18n = useI18N();

  //const { t, i18n } = useTranslation();
  let history = useHistory();
  // const HeaderButtonColor = history.location.pathname && history.location.pathname === "/homePage" ?
  //                      "HomepageColor" : (history.location.pathname.includes("/knowledge-base") || history.location.pathname.includes("/about-us") || history.location.pathname.includes("/contact-us")) ?
  //                      "knowledgeBaseColor" : null;
  const hasBackGround = ROUTES_WITHOUT_GRAY_BACKGROUND.includes(history.location.pathname);
  const HeaderButtonColor = hasBackGround  ? "HomepageColor" : (history.location.pathname.includes("/knowledge-base") || history.location.pathname.includes("/about-us") || history.location.pathname.includes("/contact-us")) ? "knowledgeBaseColor" : null;
  const dispatch = useDispatch();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [userAccessNewData, setUserAccessData] = useState([]);
  const accessCatData =
    useSelector((state: any) =>
      state!.userDataReducer!.userAccess === "No Access for User"
        ? state!.accessCategoryReducer!.accessCatData
        : state!.userDataReducer!.userAccess
    ) || [];
  const user = useSelector((state: any) => state!.userDataReducer!.user);
  const [headerMenu, setHeaderMenu] = useState(headerPrimaryLinks);
  // Getting country code from local/session storage to set the country specific notification message.
  const country_code = sessionStorage.getItem('marketIso');


  const navigation = (e, value) => {
    e && e.preventDefault();
    history.push({ pathname: value });
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  useEffect(() => {
    async function fetchData() {
      let principalEmail: GetTeamInterface =
        user.emailID || localStorage.getItem("email") || "";
        const tierEnabled = await getConfig();
        sessionStorage.setItem('isTierEnabled',tierEnabled);
        const validateIsTierEnabled =  tierEnabled === DashboardTierEnabled.ZERO || tierEnabled === DashboardTierEnabled.ONE ? true : false;
        if(validateIsTierEnabled){
        const dashBoardTypes = await getDashBoardsByUserId(principalEmail);
        if(dashBoardTypes){
          dashBoardTypes.length > 0 && localStorage.setItem("dashboard_first_id", dashBoardTypes[0].dashboard_id)
          dispatch(updateDashBoardTypes(dashBoardTypes))
        }
       }
      if (accessCatData.length === 0) {
        const userAccessData = await getUserAccess(principalEmail);
        dispatch(updateUserAccessData(userAccessData));
        if (
          userAccessData === "No Access for User" //||
          //userAccessData.length == 0
        ) {
          const accessCatData = await getAccessCategory();
          setUserAccessData(accessCatData);
          dispatch(updateAccessCategoryData(accessCatData));
        } else {
          setUserAccessData(
            userAccessData.filter((item: any) => item.access !== "na")
          );
        }
        //
      } else {
        setUserAccessData(
          accessCatData.filter((item: any) => item.access !== "na")
        );
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const links = [];

    userAccessNewData &&
      userAccessNewData.length &&
      userAccessNewData.map((item: any) => {
        if (item.category === Category.DASHBOARD)
          //links.push({ id: 2, title: "Dashboard", to: "dashboard" });
          //links.push({ id: 2, title: i18n('header.dashboard'), to: "dashboard" });
          links.push({ id: 2, title: 'header.dashboard', to: "/dashboard" });
        else if (item.category === Category.CATALOGUE)
          links.push({ id: 3, title: "header.catalog", to: "/Catalogue" });
        else if (item.category === Category.PRICING)
          links.push({ id: 4, title: "header.pricing", to: "/Pricing" });
        else if (item.category === Category.PROMOTIONS)
          links.push({ id: 5, title: "header.Promotions", to: "/Promotions" });
        else if (item.category === Category.MERCHANDISING)
          links.push({ id: 6, title: "header.merchandising", to: "/Merchandising" });
        // else if(item.category == "Help center")
        //links.push({ id: 6, title: "Help center", to: "help-center", active: false })
        else if (item.category === Category.RETAILERSUPPORT)
          links.push({
            id: 8,
            title: "header.retailer",
            to: "Retailer",
            active: false,
          });
      });
    setHeaderMenu([...headerMenu, ...links]);
  }, [userAccessNewData]);

  const getUserNotifications = async () => {
    let data = [];
    let principalReq: GetTeamInterface;
    principalReq =
      (localStorage.getItem("email")
        ? localStorage.getItem("email")
        : sessionStorage.getItem("email")) || "";
    const result = await getNotifications(principalReq);
    if (result && result.statusMessage === "SUCCESS") {
      data = result["response-data"];
    }
    setNotificationList(data);
    setNotificationCount(data.count);
  };

  const markAllNotificationsRead = async () => {
    let data = "";
    let principalReq: GetTeamInterface;
    principalReq =
      (localStorage.getItem("email")
        ? localStorage.getItem("email")
        : sessionStorage.getItem("email")) || "";
    const result = await markAllNotifications(principalReq);
    if (result && result.statusMessage === "SUCCESS") {
      data = result["response-data"];
      getUserNotifications();
    }
  };

  const updateCount = () => {
    // setNotificationCount(0);
  };

  const updateNotification = async (item) => {
    let data = "";
    let emailID =
      (localStorage.getItem("email")
        ? localStorage.getItem("email")
        : sessionStorage.getItem("email")) || "";
    let principalReq: GetNotificationStatus;
    principalReq = new updateNotificationRequest(
      emailID,
      JSON.parse(item.id),
      "read"
    );
    const result = await updateNotificationStatus(principalReq);
    if (result && result.statusMessage === "SUCCESS") {
      data = result["response-data"];
      getUserNotifications();
    }
  };

	const handleLogout = async () => {
		let principalReq: LogoutInterface;
		const emailId =
		  (localStorage.getItem("email")
			? localStorage.getItem("email")
			: sessionStorage.getItem("email")) || "";
		const refreshToken =
		  (localStorage.getItem("refreshToken")
			? localStorage.getItem("refreshToken")
			: sessionStorage.getItem("refreshToken")) || "";
		principalReq = new LogoutRequest(emailId, refreshToken, false);
		const result = await logoutService(principalReq);
		if ((result && result["response-data"] && result["response-data"].status === LoginStatus.LogOut) || (result && result["response-data"].status === LoginStatus.NoSession) || (result && result["response-data"].status === LoginStatus.UserDoesNotExists)) {
		  localStorage.removeItem("refreshToken");
		  localStorage.removeItem("idToken");
		  localStorage.removeItem("email");
		  sessionStorage.removeItem("refreshToken");
		  sessionStorage.removeItem("idToken");
      localStorage.removeItem("dashboard_first_id");
      WebEngageHelper.logout();
      history.push({pathname:"/login",state:{logout:true}});
       dispatch(resetUserData());
      dispatch(resetLoginData());
		}
    localStorage.removeItem('isStayLogin');
	};

  const handleHeaderLogo = () => {
    history.push({ pathname: "/" });
  };

  return (
    <Container className={`header-wrap ${HeaderButtonColor && HeaderButtonColor}`}>
      <Row className={"header-layout no-margin"}>
        <Col xs={1} className={"no-padding"}>
          <div className={"logoContainer"}>
            <img
              className="d-md-block"
              src={groLogo}
              alt="Gro 24/7 Logo"
              onClick={handleHeaderLogo}
            />
          </div>
        </Col>
        <Col xs={8} className={"header-primary-links"}>
          <ul>
            {headerMenu &&
              headerMenu
                .sort((a, b) => a.id - b.id)
                .map((item, i) => {
                  return (
                    <li key={i}>
                      <NavLinkItem
                        to={item.to}
                         //title={props.t(item.title)}
                        title={i18n(item.title)}
                        active={
                          item.to.replace("/", "") ===
                          props.activePage.replace("/", "")
                            ? true
                            : false
                        }
                      />
                    </li>
                  );
                })}
          </ul>
        </Col>
        <Col xs={3} className={"header-right"}>
          <div className={"country-dropdown"}>
            <LanguageSelector></LanguageSelector>
          </div>
          <div className={"notifications-dropdown"}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic-notifications">
                <img
                  src={notificationIcon}
                  alt={"notifications"}
                  onClick={() => updateCount()}
                />
                {notificationCount > 0 && (
                  <span className={"notification-count"}>
                    {notificationCount}
                  </span>
                )}
              </Dropdown.Toggle>

				<Dropdown.Menu>
					<div className={'custom-scroll'}>
						<div className={"notification-title"}>
						<h3>{Notifications.TITLE}</h3>
						<p onClick={() => markAllNotificationsRead()}>
							{Notifications.MARK_ALL_READ}
						</p>
						</div>
						{notificationList.notification &&
						notificationList.notification.length > 0 &&
						notificationList.notification.map((item) => {
							return (
							<Dropdown.Item
								key={item.id}
								onClick={() => updateNotification(item)}
							>
								<h4
								className={
									item.status === "unread"
									? "message-unread"
									: "message-read"
								}
								>
								{item.type === Notifications.INVITATION_TYPE
									? `${item.name} accepted your invite`
									: `${item.name} has not accepted your invite`}
								</h4>
								<p>
								{item.type === Notifications.INVITATION_TYPE
									? `${i18n("notifications.inviteAcceptedMsg1")} ${item.name} ${i18n("notifications.inviteAcceptedMsg2")}`
									: `${i18n("notifications.failedLoginMsg1")} ${item.name} ${i18n("notifications.failedLoginMsg2")} ${country_code && country_code === MARKETS.THAILAND || country_code === MARKETS.PHILIPPINES || country_code === MARKETS.TURKEY ? '1 hr' : '15 days' }. ${i18n("notifications.failedLoginMsg3")}`}
								</p>
								<span>{moment(item.created_at).format('DD MMMM, YYYY, h:mma')}</span>
							</Dropdown.Item>
							);
						})}
					</div>
				</Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={"profile-dropdown"}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic-profile">
                <img
                  className="profilePic"
                  src={(user && user.photo_link) ? user.photo_link : profileAvatar}
                  alt={"profile avatar"}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => navigation(e, "/myProfile")}
                  href="#"
                >
                  <img src={userIcon} alt={"profile"} />
                  {i18n("header.profileHeader")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => navigation(e, "/teamMember")}
                  href="#"
                >
                  <img src={teamIcon} alt={"my team"} />
                  {i18n("header.MyteamHeader")}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>
                  <img src={logoutIcon} alt={"Log out"} />
                  {i18n("header.logoutHeader")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Header;
//export default withTranslation()(Header);
