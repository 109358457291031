import React from "react";
import { Row, Col } from "react-bootstrap";
import Logo from "../logo/logo";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import "./Footer.scss";
import LogoPurple from "../../assets/images/Logo-purple.svg";
import UseI18N from "../../helper/i18nHelpers";

/* List of links */


const Footer = () => {
  const i18n=UseI18N();
  const footerLinkList = [
  
    { title: i18n("home.homeAboutusFooter"), to: "about-us" },
    { title: i18n("home.homeContactusFooter"), to: "contact-us" },
    { title: i18n("home.homeknowledgebase"), to: "knowledge-base" },
    { title: i18n("home.homeTermsandConditions"), to: "terms-and-conditions" },
  ];
  return (
    <>
      <div className={"footer-wrap"}>
        <Row className={"footer-top no-margin"}>
          <Col xs={2} className={"no-padding"}>
            <Logo logo={LogoPurple} />
          </Col>
          <Col className={"footer-links no-padding"}>
            <ul>
              {footerLinkList &&
                footerLinkList.map((item) => {
                  return (
                    <li key={item.title}>
                      <NavLinkItem
                        to={item.to}
                        title={item.title}
                        active={false}
                      />
                    </li>
                  );
                })}
            </ul>
          </Col>
        </Row>
        <Row className={"footer-bottom"}>
          <Col xs={2}>
            <p>{i18n("home.homegro24/7 © 2021-2022")}</p>
          </Col>
          <Col className={"footer-rights"}>
            <p>{i18n("home.homeAllrightsreserved")}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
