import store from "../store";

const principalAPI = (() => {
  // const BASE_URL = `https://apiqa.jechservices.com:443/principalportal-exp-v1/api`;
  // const BASE_TICKET_URL = `https://apiqa.jechservices.com:443/freshdesk-sys-v1/api`;

  const API = {
    getConfigData() {
      let configStoreData: any = {};
      if(store) {
        const state = store.getState();
        configStoreData = state && state.configDataReducer.config;
      }
      return configStoreData;
    },
    getAPIURL(urlText: string) {
      let configData = this.getConfigData();
      let baseUrl = configData && configData.muleSoft ? configData.muleSoft.baseUrl : sessionStorage.baseUrl;
      return `${baseUrl}/${urlText}`;
    },
    getTicketAPIURL(urlText: string) {
      let configData = this.getConfigData();
      let baseTicketUrl = configData && configData.muleSoft ? configData.muleSoft.baseTicketUrl : sessionStorage.baseTicketUrl
      return `${configData && configData.muleSoft && configData.muleSoft.baseTicketUrl}/${urlText}`;
    },
  };
  return API;
})();

export default principalAPI;
