import {
    GET_FILER_CREATORS, UPDATE_CHECKBOX_FILER_CREATORS
  } from '../../actionTypes/getTeamMembers/creatorActionTypes';
  
  const initialState = {
    creatorList:{}
  };
  
  const getCreatoresReducers = (state = initialState, action: any) => {
    switch (action.type) {
      case GET_FILER_CREATORS:
        return {
          ...state,
          creatorList: action.payload ? action.payload : {},
        };
        case UPDATE_CHECKBOX_FILER_CREATORS:
        return {
          ...state,
          creatorList: state.creatorList.map(team=>{
            if(team.requester_id === action.payload.requester_id){
              team.checked = action.payload.value;
              return team;
            }
            return team;
          }),
        }
      default:
        return state;
    }
  };
  
  export default getCreatoresReducers;